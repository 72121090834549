import PropTypes from "prop-types"
import { useEffect, useMemo } from "react"
// @mui
import { Box, Drawer, Stack } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
// hooks
import useCollapseDrawer from "minimals-template/components/@hooks/useCollapseDrawer"
import useResponsive from "minimals-template/components/@hooks/useResponsive"
import { NavSectionVertical } from "minimals-template/components/nav-section"
import Scrollbar from "minimals-template/components/Scrollbar"
// utils
import cssStyles from "minimals-template/components/utils/cssStyles"
// config
import { NAVBAR } from "config"
// components
//
import { CollapseNavBar, CollapseSideBar, RefreshNavBar } from "event-definitions"
import { getLeftBarNavigationItems } from "ioc"
import CollapseButton from "routes/@layouts/app/navbar/CollapseButton"
import { NavbarFooter, NavbarHeader } from "slot-definitions"
import { useLocation } from "react-router"
import { ReactComponent as FacilitiesLogo } from "routes/@assets/Icon - Facilities-iQ.svg"
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        flexShrink: 0,
        transition: theme.transitions.create("width", {
            duration: theme.transitions.duration.shorter,
        }),
    },
}))

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
}

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
    CollapseSideBar.useEvent(onCloseSidebar)

    RefreshNavBar.useRefresh()
    const navigation = getLeftBarNavigationItems()
    navigation.sortBy((n) => n.subPaths?.priority ?? 100)

    const groups = navigation.filter((f) => !f.subPaths?.isVisible || f.subPaths.isVisible()).groupBy("subPaths.group")

    let navConfig = [{ subheader: null, items: groups.undefined }]
    delete groups.undefined
    navConfig = [
        ...navConfig,
        ...Object.entries(groups).map(([subheader, items]) => ({
            subheader: items[0].subPaths?.groupContent ?? subheader,
            items,
        })),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const finalConfig = useMemo(() => navConfig, [JSON.stringify(navConfig)])

    const theme = useTheme()

    const location = useLocation()

    const isDesktop = useResponsive("up", "lg")
    const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onCloseCollapse, onHoverEnter, onHoverLeave } =
        useCollapseDrawer()

    CollapseNavBar.useEvent(onCloseCollapse)

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                pt: "env(safe-area-inset-top)",
                "& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 0,
                    flexShrink: 0,
                    ...(isCollapse && { alignItems: "center" }),
                }}
            >
                <Stack direction="row" alignItems="center" sx={{ width: 1, minHeight: 48 }}>
                    <Box mx={3} width={36} height={36}>
                        <FacilitiesLogo />
                    </Box>
                    <Box flex={1} />

                    {isDesktop && !isCollapse && (
                        <>
                            {/*<Typography component="div" sx={{ mr: -1 }} color="text.secondary" variant="caption">*/}
                            {/*    from*/}
                            {/*</Typography>*/}
                            {/*<Logo />*/}
                            <CollapseButton
                                data-cy="navItem-collapseButton"
                                onToggleCollapse={onToggleCollapse}
                                collapseClick={collapseClick}
                            />
                        </>
                    )}
                </Stack>
                <NavbarHeader.Slot />
            </Stack>
            <NavSectionVertical
                data-cy="left-navbar"
                navConfig={finalConfig}
                isCollapse={isCollapse}
                id="navbarVertical"
            />
            <NavbarFooter.Slot />
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    )

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
                },
                ...(collapseClick && {
                    position: "absolute",
                }),
            }}
        >
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: NAVBAR.DASHBOARD_WIDTH,
                            borderRightStyle: "dashed",
                            bgcolor: "background.default",
                            transition: (theme) =>
                                theme.transitions.create("width", {
                                    duration: theme.transitions.duration.standard,
                                }),
                            ...(isCollapse && {
                                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                            }),
                            ...(collapseHover && {
                                ...cssStyles(theme).bgBlur(),
                                boxShadow: (theme) => theme.customShadows.z24,
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    )
}
