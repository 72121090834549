import { Box, Button, IconButton } from "@mui/material"
import { buttonStyles } from "lib/@components/button-styles"
import { useRef, useState } from "react"
import { prevent } from "lib/prevent"
import { uploadFiles } from "lib/@components/uploadButton"
import { noop } from "lib/noop"
import Iconify from "minimals-template/components/Iconify"

export function UploadFileButton({
    onClick = noop,
    onRawFile = () => true,
    Component = Button,
    accept = "*",
    children,
    multiple,
    ...props
}) {
    const classes = buttonStyles()
    const inputHolder = useRef()
    const fileInput = useRef()
    const [uploading, setUploading] = useState(false)

    return (
        <>
            <Component
                component="div"
                endIcon={
                    Component === Button &&
                    uploading && (
                        <IconButton size="small" sx={{ color: "white" }}>
                            <Iconify icon="eos-icons:hourglass" />
                        </IconButton>
                    )
                }
                {...props}
                onClick={prevent(selectFile)}
            >
                {children}
            </Component>
            <Box
                className="upload-button-input"
                ref={inputHolder}
                style={{
                    width: 0,
                    height: 0,
                    display: "none",
                    position: "absolute",
                }}
            >
                <input
                    ref={fileInput}
                    className={`file-input ${classes.uploadButton}`}
                    onChange={gotFile}
                    type="file"
                    accept={accept}
                    multiple={multiple}
                />
            </Box>
        </>
    )

    async function gotFile(e) {
        try {
            setUploading(true)
            const { target: { files = [] } = {} } = e
            if (onRawFile(files)) {
                await uploadFiles(files, onClick)
            }
            clearInputFile(fileInput.current)
        } finally {
            setUploading(false)
        }
    }

    function selectFile() {
        const input = document.createElement("input")
        input.type = "file"
        input.multiple = multiple
        input.accept = accept
        input.style.display = "none"
        input.onchange = gotFile
        document.body.appendChild(input)
        setTimeout(() => {
            input.click()
            input.value = ""
        }, 200)
    }

    function clearInputFile(f) {
        if (!f) return
        f.value = ""
        // For older browsers if above doesn't work
        if (f.value) {
            f.type = "text"
            f.type = "file"
        }
    }
}
