import { gql } from "@apollo/client"
import { Autocomplete, Box, Chip, createFilterOptions, TextField } from "@mui/material"
import { BasketUpdate } from "event-definitions"
import { useDialog } from "lib/@hooks/useDialog"
import { mutate } from "lib/graphql/mutate"
import { keyBy } from "lib/keyBy"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { sortBy } from "lib/sortBy"
import { HelpTag, HELPTAG_TAGS } from "routes/help/helpTag"
import { CreateNewTagDialog } from "routes/schedule/plugins/baskets/tags/create-new-tag-dialog"
import { TagInfo } from "routes/schedule/plugins/baskets/tags/tag-info"
import { tagColor } from "routes/schedule/plugins/baskets/tags/tagColor"
import { useTagsAndAllPossibleTags } from "routes/schedule/plugins/baskets/tags/useTagsAndAllPossibleTags"
import { ScheduleSelectorBeforeList, ScheduleSelectorToolbar } from "slot-definitions"
import "../tree/basket-bar"
import "./tagbar"
import { useState } from "react"
import { setFromValueParam } from "lib/setFromEvent"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { notInSelector } from "routes/facilities/lib/when-parent-item"
import { TruncatedTypography } from "lib/@components/truncated-typography"

export const TagUpdated = BasketUpdate("Tag", "*")

const filter = createFilterOptions()

ScheduleSelectorToolbar("basket-Tag").plug(replaceAllWith(<Frag />, 2000))
ScheduleSelectorToolbar("basket-ScheduleBasket").plug(replaceAllWith(<Frag />, 2000))

export function Tags({ referenceId, placeholder = "Enter a tag...", type = "Tag", ...props }) {
    const { schedule } = useBoundContext()
    const openNewTagDialog = useDialog(<CreateNewTagDialog type={type} />)
    const { tags, allTags, loading } = useTagsAndAllPossibleTags(referenceId, type)
    console.log({ tags })
    TagUpdated.useRefresh()
    const [inputValue, setInputValue] = useState("")
    if (loading) return <Box height={30} />
    const lookup = keyBy(allTags.baskets, (v) => v.id)
    return (
        <>
            {!tags.length && <Box height={3} />}
            <Autocomplete
                className="tags"
                {...props}
                multiple
                freeSolo
                autoHighlight
                inputValue={inputValue}
                onInputChange={setFromValueParam(setInputValue)}
                onChange={handleListChanged}
                value={[...tags].sortBy((t) => t.name).map("id")}
                isOptionEqualToValue={({ id }, value) => id === value}
                disabled={schedule?.retired}
                renderInput={(params) => (
                    <TextField
                        variant="standard"
                        {...params}
                        placeholder={!schedule?.retired ? placeholder : ""}
                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                    />
                )}
                options={sortBy(
                    allTags.baskets.unique("name").filter((c) => c.canEdit !== false),
                    (c) => c.name.toLowerCase()
                )}
                getOptionLabel={(v) => v.name}
                filterOptions={filterListOfOptions}
                renderTags={(value, getTagProps) =>
                    value.map((tag, index) => {
                        const option = lookup[tag]
                        const tagProps = getTagProps({ index })
                        const [backgroundColor, color] = tagColor(option)
                        return (
                            !!option && (
                                <Chip
                                    {...tagProps}
                                    sx={{
                                        "&.MuiChip-root": {
                                            color,
                                            backgroundColor: `${backgroundColor} !important`,
                                        },
                                    }}
                                    onDelete={option.canEdit && !schedule?.retired ? tagProps.onDelete : null}
                                    color="primary"
                                    key={option.id}
                                    label={
                                        <Box maxWidth={400}>
                                            <TruncatedTypography>{option.name}</TruncatedTypography>
                                        </Box>
                                    }
                                />
                            )
                        )
                    })
                }
            />
            {!tags.length && <Box height={4} />}
        </>
    )

    async function handleListChanged(_, updatedValue) {
        let toBeResolved = false
        // eslint-disable-next-line no-unused-vars
        const updatedTags = updatedValue
            .map((value) => {
                if (typeof value === "string") return value
                if (value.id) return value.id
                toBeResolved = value.inputValue
                return null
            })
            .filter(Boolean)

        setInputValue("")
        if (toBeResolved) {
            const tagId = await openNewTagDialog({ suggestedTag: toBeResolved })
            if (tagId) {
                try {
                    await mutate(
                        gql`
                            mutation AddScheduleToTag($basketId: String!, $referenceId: String!) {
                                addItemsToBasket(
                                    id: $basketId
                                    items: [{ referenceId: $referenceId, id: $referenceId }]
                                )
                            }
                        `,
                        {
                            basketId: tagId,
                            referenceId,
                        }
                    )
                } catch (e) {
                    errorSnackbar(e.message)
                }
            }
        } else {
            const existing = new Set(tags.map("id"))
            const newTags = new Set()
            for (const tag of updatedTags) {
                if (!existing.has(tag)) {
                    newTags.add(tag)
                }
                existing.delete(tag)
                setInputValue("")
            }
            try {
                for (const tagId of existing) {
                    // eslint-disable-next-line no-await-in-loop
                    await mutate(
                        gql`
                            mutation RemoveTags($basketId: String!, $referenceId: String!) {
                                removeItemsFromBasket(id: $basketId, ids: [$referenceId])
                            }
                        `,
                        {
                            basketId: tagId,
                            referenceId,
                        }
                    )
                }
                for (const tagId of newTags) {
                    // eslint-disable-next-line no-await-in-loop
                    await mutate(
                        gql`
                            mutation AddTag($basketId: String!, $referenceId: String!) {
                                addItemsToBasket(
                                    id: $basketId
                                    items: [{ id: $referenceId, referenceId: $referenceId }]
                                )
                            }
                        `,
                        {
                            basketId: tagId,
                            referenceId,
                        }
                    )
                }
            } catch (e) {
                errorSnackbar(e.message)
            }
        }
    }

    function filterListOfOptions(options, params) {
        const result = filter(options, params)
        const { inputValue } = params
        const isExisting = allTags.baskets.some((c) => c.name.toLowerCase() === inputValue.toLowerCase())
        if (!isExisting && !!inputValue?.trim()) {
            result.push({ inputValue, name: `Add tag "${inputValue}"` })
        }
        return result
    }
}

ScheduleSelectorBeforeList("basket-Tag").plug(<TagInfo if={notInSelector} />)
ScheduleSelectorBeforeList("basket-Tag").plug(<HelpTag tags={[HELPTAG_TAGS]} />)
