import { useEffect, useRef, useState } from "react"
import noop from "lib/noop"

export function useMeasurement(ref, shouldRedraw = () => true, notify = noop) {
    const element = useRef()
    const [size, setSize] = useState({
        width: 0,
        height: 0.0000001,
        offsetTop: 0,
        offsetLeft: 0,
        element: {},
    })
    const sizeFn = useRef(setSize)
    sizeFn.current = setSize

    const [observer] = useState(() => new ResizeObserver(measure))
    useEffect(
        () => () => {
            sizeFn.current = null
            observer.disconnect()
        },
        [observer]
    )

    return [size, attach, reattach]

    function reattach() {
        attach(element.current)
    }

    function sized(...params) {
        notify(...params)
        if (shouldRedraw(...params)) {
            if (sizeFn.current) sizeFn.current(...params)
        }
    }

    function attach(target) {
        element.current = target
        if (ref) {
            if (typeof ref === "function") {
                ref(target)
            } else {
                ref.current = target
            }
        }
        if (target) {
            observer.observe(target)
        }
    }

    function measure(entries) {
        const { contentRect } = entries[0]
        if (contentRect.height > 0) {
            sized({
                height: contentRect.height | 0,
                width: contentRect.width | 0,
                left: contentRect.left | 0,
                top: contentRect.top | 0,
                element: element.current,
                offsetTop: element.current?.offsetTop,
                offsetLeft: element.current?.offsetLeft,
                parentHeight: element.current?.offsetParent?.scrollHeight,
            })
        }
    }
}
