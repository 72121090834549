import { useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"
import { HardNavigate, Navigate, ShowSnackBar } from "event-definitions"
import { adjustPath } from "lib/routes/adjust-path"

export function Navigator() {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    Navigate.useEvent((to, ...params) => {
        to = adjustPath(to)
        navigate(to, ...params)
        return false
    })
    HardNavigate.useEvent((to, ...params) => {
        navigate(to, ...params)
        return false
    })
    ShowSnackBar.useEvent((message, options) => {
        enqueueSnackbar(message, options)
    })
    return null
}
