import { Button } from "@mui/material"
import { useState } from "react"
import { prevent } from "lib/prevent"
import { readAndCompressImage } from "browser-image-resizer"
import { fileToBase64 } from "lib/file-to-base64"
import { uploadFiles } from "lib/@components/uploadButton"
import { noop } from "lib/noop"
import Iconify from "minimals-template/components/Iconify"

export function ImageUploadButton({
    children,
    maxWidth,
    maxHeight = 10000,
    onLoading = noop,
    onFile,
    onRaw,
    fileType,
    onClick = noop,
    Component = Button,
    accept = "image/*",
    multiple = false,
    ...props
}) {
    let input
    const [uploading, setUploading] = useState(false)
    if (Component !== Button) {
        return (
            <Component {...props} onClick={prevent(click)}>
                {children}
            </Component>
        )
    }
    return (
        <Component {...props} onClick={prevent(click)} endIcon={uploading && <Iconify icon="eos-icons:hourglass" />}>
            {children}
        </Component>
    )

    function click() {
        input = document.createElement("input")
        input.type = "file"
        delete input.capture
        input.accept = accept
        input.onchange = uploadFile
        input.multiple = multiple
        document.body.appendChild(input)
        input.click()
        if (!window.Cypress) {
            document.body.removeChild(input)
        }
    }

    async function uploadFile({ target: input }) {
        const { files } = input
        if (window.Cypress) {
            document.body.removeChild(input)
        }
        onLoading(true)
        for (const file of files) {
            if (maxWidth) {
                maxHeight ??= Math.floor((maxWidth / 16) * 10)
            } else {
                maxHeight ??= 1024
            }
            maxWidth ??= Math.floor((maxHeight / 10) * 16)
            // eslint-disable-next-line no-await-in-loop
            const resized = await readAndCompressImage(file, {
                quality: 0.7,
                maxWidth,
                maxHeight,
                mimeType: fileType ?? file.type,
                autoRotate: true,
            })
            if (onClick && onClick !== noop) {
                try {
                    setUploading(true)
                    // eslint-disable-next-line no-await-in-loop
                    await uploadFiles([resized], (href) => onClick(href, file))
                } finally {
                    setUploading(false)
                }
            } else if (onRaw) {
                onRaw(resized)
            } else {
                // eslint-disable-next-line no-await-in-loop
                onFile(await fileToBase64(resized))
            }
        }
        input.value = ""

        onLoading(false)
    }
}
