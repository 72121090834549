import { forwardRef } from "react"
import { useMobile } from "lib/@components/mobile"
import { Box, Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { cleanState } from "routes/schedule/components/schedule-breadcrumbs"
import noop from "lib/noop"

export const StandardLastBreadcrumb = forwardRef(function StandardLastBreadcrumb(
    { onClick, caption = "", hasList },
    ref
) {
    const isMobile = useMobile()
    if (typeof caption === "string") {
        caption = cleanState(caption)
    }
    return (
        <Stack
            ref={ref}
            sx={{ cursor: hasList && onClick ? "pointer" : undefined }}
            direction="row"
            spacing={1}
            alignItems="center"
            onClick={hasList ? onClick : noop}
        >
            <Box
                className="breadcrumb last-breadcrumb"
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",

                    fontWeight: "bold",
                    color: "primary.dark",
                }}
            >
                {typeof caption !== "string"
                    ? caption
                    : isMobile
                    ? (caption ?? "").truncate(22)
                    : (caption ?? "").truncate(40)}
            </Box>
            {!!hasList && <Iconify sx={{ fontSize: 20 }} icon="material-symbols:arrow-drop-down-rounded" />}
        </Stack>
    )
})
