// @mui
import { styled } from "@mui/material/styles"
import { AppBar, Box, Stack, Toolbar } from "@mui/material"
// hooks
import Logo from "minimals-template/components/Logo"
import Iconify from "minimals-template/components/Iconify"
import { IconButtonAnimate } from "minimals-template/components/animate"
import useOffSetTop from "minimals-template/components/@hooks/useOffSetTop"
import useResponsive from "minimals-template/components/@hooks/useResponsive"
// utils
import cssStyles from "minimals-template/components/utils/cssStyles"
// config
import { HEADER, NAVBAR } from "config"
// components
//
import { DashboardHeaderLeft, HeaderBarRight } from "slot-definitions"
import useCollapseDrawer from "minimals-template/components/@hooks/useCollapseDrawer"
import { NotOnSharing } from "lib/@components/sharing/not-sharing"
import { isMobileApp } from "routes/@layouts/app/header/is-mobile-app"

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: "none",
    paddingTop: isMobileApp() ? 60 : 0,
    height: HEADER.MOBILE_HEIGHT,
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(["width", "height"], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up("lg")]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
        ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
        ...(verticalLayout && {
            width: "100%",
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            backgroundColor: theme.palette.background.default,
        }),
    },
}))

// ----------------------------------------------------------------------

export default function DashboardHeader({ onOpenSidebar, verticalLayout = false }) {
    const { isCollapse = false } = useCollapseDrawer()
    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout
    const isDesktop = useResponsive("up", "lg")

    return (
        <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
            <Toolbar
                sx={{
                    minHeight: "100% !important",
                    px: { lg: 5 },
                }}
            >
                {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
                {!isDesktop && (
                    <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
                        <Iconify icon="eva:menu-2-fill" />
                    </IconButtonAnimate>
                )}

                <NotOnSharing>
                    <DashboardHeaderLeft.Slot />
                </NotOnSharing>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <HeaderBarRight.Slot />
                </Stack>
            </Toolbar>
        </RootStyle>
    )
}
