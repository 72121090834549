import { getMyShareLinks } from "routes/shared-with-me/controller/get-my-share-links"
import { Grid } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { LinkCard } from "routes/shared-with-me/link-card"
import { invalidate } from "lib/graphql/cache"
import { SharingLinkUpdated } from "event-definitions"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { useSearchParam } from "lib/@hooks/use-search-param"

SharingLinkUpdated.handleOnce((users) => {
    if (users.some((user) => window._authorization?.user?.email?.endsWith(user))) {
        invalidate("getMyShareLinks")
    }
})

export function Links() {
    const [search] = useSearchParam("search", "")
    const links = getMyShareLinks.useResults(search)

    return (
        <>
            <SearchBoxUsingSearchParam placeholder="Filter..." />
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Repeat list={links?.links} item={<LinkCard />} />
            </Grid>
        </>
    )
}
