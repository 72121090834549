// ----------------------------------------------------------------------

import uniqolor from "uniqolor"

function getInitials(name = "") {
    const parts = name.split(" ")
    if (parts.length === 1) return parts[0].charAt(0).toUpperCase()
    return `${parts[0].charAt(0).toUpperCase()}${parts.at(-1).charAt(0).toUpperCase()}`
}

export default function createAvatar(name) {
    return {
        name: getInitials(name),
        color: uniqolor(name, {
            saturation: [65, 99],
            lightness: [65, 87],
        }).color,
    }
}
