const Sugar = require("sugar")
require("sugar-inflections")

// Define the keyBy method
Sugar.Array.defineInstance({
    keyBy(array, key) {
        const original = key
        key = typeof key === "string" ? (v) => v[original] : key
        return array.reduce((result, item) => {
            result[key(item)] = item
            return result
        }, {})
    },
})

// Now use Sugar.js to extend all arrays with the keyBy method
Sugar.extend()
