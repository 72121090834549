import { MenuItem, OutlinedInput, Select, Tooltip } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { useClientInfo } from "minimals-template/components/contexts/NognitoContext"
import { hasDemand } from "lib/authorization/has-demand"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { If } from "lib/@components/switch"

const inputStyles = {
    color: "primary",
    typography: "body2",
    border: "none",
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    ".MuiOutlinedInput-input": {
        py: 0,
        pl: 0,
        color: "primary.main",
        textOverflow: "ellipsis",
    },
    width: "100%",
    padding: 0,
    margin: 0,
}

const menuStyles = {
    "& .MuiPaper-root": {
        maxHeight: 300,
        textAlign: "left",
        color: "primary",
        border: "none",
    },
}

export function UserClientSwitcher({ allowClientSwitching, adoptingClient = false, user }) {
    const { clients, setSelectedClient, selectedClient } = useBoundContext()
    const { id: clientId, displayName, name } = useClientInfo() ?? {}
    const defaultMessage = clients[0]?.name || clients[0]?.displayName
    const overrideMessage = adoptingClient ? `${name} (Adopted)` : null
    if (!clients) return null

    return (
        <If
            truthy={allowClientSwitching}
            then={
                !hasDemand("$sharing") && (
                    <>
                        {clients?.length > 1 ? (
                            <Select
                                defaultValue={clientId?.split(":")[0]}
                                className="user-client-switcher"
                                value={selectedClient}
                                input={<OutlinedInput label="Client" sx={inputStyles} />}
                                sx={{ ...inputStyles, maxWidth: 185 }}
                                MenuProps={{ sx: menuStyles }}
                                onChange={setFromEvent(setSelectedClient)}
                            >
                                {clients.map((clientRecord) => (
                                    <MenuItem
                                        className="companyNameSelection"
                                        key={clientRecord?.id}
                                        value={clientRecord?.id}
                                        sx={{ ...inputStyles, padding: "auto" }}
                                    >
                                        {clientRecord?.name || clientRecord?.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <Tooltip title={overrideMessage || defaultMessage}>
                                <OutlinedInput
                                    label="Client"
                                    data-cy="client-name"
                                    disabled
                                    sx={inputStyles}
                                    value={overrideMessage || defaultMessage}
                                    readOnly
                                />
                            </Tooltip>
                        )}
                    </>
                )
            }
            else={
                <Tooltip title={user.company ?? name ?? displayName}>
                    <OutlinedInput
                        label="Client"
                        data-cy="client-name"
                        disabled
                        sx={inputStyles}
                        value={user.company ?? name ?? displayName}
                        readOnly
                    />
                </Tooltip>
            }
        />
    )
}
