import Iconify from "minimals-template/components/Iconify"
import { getTreeRootElements } from "ioc"
import { inPriorityOrder } from "lib/in-priority-order"
import { useMobile } from "lib/@components/mobile"
import noop from "lib/noop"
import { useMemo, useState } from "react"
import { RefreshTreeRoots, TreeRootsUpdated } from "event-definitions"
import { getTreeItemIndex } from "routes/schedule/lib/getTreeItemIndex"
import { setTreeIndex } from "routes/schedule/lib/getTreeIndex"
import { addDebugItem } from "debug-window"
import { debounce } from "lib/debounce"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"

export function useTreeRoots() {
    const [roots, setRoots] = useState()
    const isMobile = useMobile()
    const reindexed = useRefresh(noChange)

    const refresh = RefreshTreeRoots.useRefresh(noChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(
        debounce(async () => {
            try {
                const roots = await getTreeRootElements()
                const index = getTreeItemIndex({ id: "_root", children: roots })
                setTreeIndex(index)
                setRoots(roots.sort(inPriorityOrder))
                TreeRootsUpdated.raiseOnce()
                reindexed()
                addDebugItem("Did update the tree index")
            } catch (e) {
                console.warn("Tree roots not available", e.message, e.stack)
            }
        }, 100),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refresh.id]
    )
    return {
        children: roots,
        label: (
            <Iconify
                sx={{ fontSize: isMobile ? 16 : 24, mt: 0.5, lineHeight: 0 }}
                icon="material-symbols:home-rounded"
            />
        ),
        id: "_root",
        onExpand: noop,
        onCollapse: noop,
    }
}
