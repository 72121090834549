import noop from "lib/noop"
import { InMemoryCache } from "@apollo/client"
import { FlushGraphQLCache, HasInvalidated, UserLoggedOut } from "event-definitions"
import { handle, raise } from "library/local-events"
import { ensureArray } from "lib/ensure-array"

let cache

export function createCache() {
    cache = new InMemoryCache({
        typePolicies: {
            Schedule: {
                keyFields: ["id", "_id", "version"],
            },
            Basket: {
                fields: {
                    items: {
                        merge(existing, incoming) {
                            return incoming
                        },
                    },
                },
            },
        },
    })
    return cache
}

FlushGraphQLCache.before.handle(async () => {
    await cache.reset({ discardWatches: false })
})

function removeFrom(target, startsWith, root, cb = noop) {
    if (!target) return
    for (const key of Object.keys(target)) {
        if (key.startsWith(startsWith)) {
            if (root) {
                cache.evict({ id: root, fieldName: key })
            } else {
                cache.evict({ id: key })
            }
            cb(key)
        }
    }
}

export function evict(startsWith, cb = noop) {
    if (!startsWith) return
    removeFrom(cache.data.data.ROOT_QUERY, startsWith, "ROOT_QUERY", cb)
    removeFrom(cache.data.data.ROOT_MUTATION, startsWith, "ROOT_MUTATION", cb)
    removeFrom(cache.data.data.ROOT_SUBSCRIPTION, startsWith, "ROOT_SUBSCRIPTION", cb)
    removeFrom(cache.data.data, startsWith, undefined, cb)
}

UserLoggedOut.handleOnce(() => {
    console.log("User Logged out")
    cache.reset()
})

FlushGraphQLCache.handleOnce(() => {
    evict("", (key) => {
        key = key.split("(")[0]
        HasInvalidated.raiseOnce(key)
        HasInvalidated(key).raiseOnce(key)
    })
})

handle("Invalidate", (name) => {
    for (const query of ensureArray(name)) {
        evict(query)
        HasInvalidated.raiseOnce(query)
        HasInvalidated(query).raiseOnce(query)
    }
})

export function invalidate(name) {
    raise("Invalidate", name)
}
